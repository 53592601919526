import React, { Component } from 'react';
import styled from 'styled-components';

export class Col extends Component {
  render() {
    return <StyledCol {...this.props} />;
  }
}

Col.defaultProps = {
  gap: '0px',
};

const calcWidth = (columns, gap) => {
  if (columns === 12) {
    return `100%`;
  }

  return `calc(${(columns * 100) / 12}% - ${gap})`;
};

const StyledCol = styled.div`
  width: 100%;
  flex: 0 1 ${({ xs, gap }) => calcWidth(xs, gap)};
  @media (min-width: 768px) {
    flex: 0 1 ${({ sm, gap }) => calcWidth(sm, gap)};
    width: ${({ sm, gap }) => `${(sm * 100) / 12}%`};
  }
  @media (min-width: 1024px) {
    width: ${({ md, gap }) => `${(md * 100) / 12}%`};
    flex: 0 1 ${({ md, gap }) => calcWidth(md, gap)};
  }
`;
